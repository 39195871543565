import { baseURL } from "../../constants/Api";
import { dateFormat_3, sessionExpireLogic } from "../../utils/functionUtils";
import { tableReserveApiEndpoints, tableReserveBaseUrl } from "./constants";

type ValuePiece = Date | null;
type Range<T> = [T, T];
type SelectedDateType = ValuePiece | Range<ValuePiece>;

export const getAvailableRestaurantsAPI = async (navigate: Function) => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(
      `${tableReserveBaseUrl}/${tableReserveApiEndpoints.getRestaurants}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();

    if (response && response.status == 200) {
      return data?.restaurants;
    } else if (response.status == 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};


export const getTimeSlotsAPI = async (reserveId: String, selectedDate: SelectedDateType, numberOfDiners: number | null, navigate: Function) => {
  // console.log(dateFormat_3(selectedDate), "date")
  const formattedDate = dateFormat_3(selectedDate)
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(
      `${tableReserveBaseUrl}/${tableReserveApiEndpoints.GET_TIME_SLOTS}/${reserveId}/${formattedDate}/${numberOfDiners}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const createBookingAPI = async (selectedDate: SelectedDateType, partySize: number, selectedTime: string, reserveId: string, userInfo: object, navigate: Function) => {
  const token = localStorage.getItem("accessToken");
  const requestBody = JSON.stringify({
    selectedDate,
    partySize,
    selectedTime,
    userInfo,
    reserveId,
  });

  // console.log("requestBodyyyyyy-----------", requestBody)

  // return
  try {
    const response = await fetch(
      `${tableReserveBaseUrl}/${tableReserveApiEndpoints.CREATE_BOOKINGS}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: requestBody
      }
    );
    const data = await response.json();

    if (response && response.status == 201) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};

export const getIndividualBooking = async (bookingId: number, id: string, rId: string) => {
  try {
    // Make the API call to the backend
    const response = await fetch(
      `${tableReserveBaseUrl}/${tableReserveApiEndpoints.INDIVIDUAL_BOOKING}?bookingId=${bookingId}&id=${id}&rId=${rId}`
    );

    // Check if the response is successful
    if (!response.ok) {
      throw new Error('Failed to fetch booking data');
    }

    // Parse the response JSON
    const data = await response.json();

    // Return the fetched data
    return data;
  } catch (error) {
    console.error('Error fetching booking:', error);
    throw error; // Re-throw the error to be handled by the caller
  }
};

export const getBookings = async (navigate: Function) => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(
      `${tableReserveBaseUrl}/${tableReserveApiEndpoints.FETCH_BOOKINGS}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      }
    );
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};


export const cancelBookingAPI = async (id: string, navigate: Function) => {
  const token = localStorage.getItem("accessToken");
  const requestBody = JSON.stringify({
    idOfBooking: id
  });

  // console.log(requestBody)

  // return

  try {
    const response = await fetch(
      `${tableReserveBaseUrl}/${tableReserveApiEndpoints.CANCEL_BOOKINGS}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: requestBody
      }
    );
    const data = await response.json();

    if (response && response.status == 200) {
      return data;
    } else if (response.status == 401) {
      alert("Session expired");
      sessionExpireLogic();
      navigate("/Login");
    } else {
      alert("Oops !! Something went wrong .");
      console.error("order api is failing");
    }
  } catch (err) {
    console.log("something went wrong");
  }
};
