// @ts-nocheck
import React, { useEffect, useState } from "react";
import "../../styleSheets/tableReserve/bookings.css";
import { BookingCard } from "./bookingCard";
import { getBookings } from "../../API/tableReserve/tableReserveAPI";
import { useNavigate } from "react-router-dom";
import BackArrow from "../../assets/icons/Social_icons/arrowSvg.svg";
import AnalyticsUtil from "../../utils/GoogleAnalytics";
import UpcomingBookings from "./UpcomingBookings";
import PreviousBookings from "./PreviousBookings";
import "./UpcomingBookings.css";

export const BookingIndex = () => {
  const [data, setData] = useState([]);
  const [toggle, setToggle] = useState(true);
  const currentDateTime = new Date();

  const navigate = useNavigate();
  const callApi = async () => {
    const dataReceived = await getBookings(navigate);
    setData(dataReceived);
  };
  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(`/my-bookings`, "Booking Page");
  }, []);

  const upcomingBookings = data.filter((booking) => {
    const slotDateTime = new Date(
      `${booking.slot.slotDate}T${booking.slot.slotStartTime}`,
    );
    return slotDateTime > currentDateTime;
  });

  const previousBookings = data.filter((booking) => {
    const slotDateTime = new Date(
      `${booking.slot.slotDate}T${booking.slot.slotStartTime}`,
    );
    return slotDateTime <= currentDateTime;
  });

  return (
    <div
      style={{
        maxWidth: "500px",
        margin: "auto",
        height: "100svh",
        backgroundColor: "#212328",
        padding: "13px",
        overflow: "auto",
      }}
      className="bookings-customScroll"
    >
      <div className="py-3 text-white d-flex align-items-center justify-content-center mb-4">
        <div className="me-auto">
          <img
            src={BackArrow}
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />
        </div>
        <div
          className="bookings-heading me-auto"
          style={{ marginLeft: "-41px" }}
        >
          Bookings
        </div>
      </div>
      {/* Toggle Switch */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "5px",
        }}
      >
        <label htmlFor="filter" className="switch" aria-label="Toggle Filter">
          <input
            type="checkbox"
            id="filter"
            checked={toggle}
            onChange={() => setToggle(!toggle)}
          />
          <span>Previous</span>
          <span>Upcoming</span>
        </label>
      </div>
      {/* Render Components Based on Toggle */}
      <div
        className="border bg-white"
        style={{ padding: "11px 10px 60px 10px", borderRadius: "10px" }}
      >
        {toggle ? (
          <UpcomingBookings bookingData={upcomingBookings} />
        ) : (
          <PreviousBookings bookingData={previousBookings} />
        )}
      </div>
    </div>
  );
};
