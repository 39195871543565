// @ts-nocheck

import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import "../../styleSheets/pop_up_2option.css";
import OptionIcon from "../../assets/icons/Social_icons/3dot.svg";
import { cancelBookingAPI } from "../../API/tableReserve/tableReserveAPI";
import { useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";
import { useSelector } from "react-redux";
import { baseURL } from "../../constants/Api";

export const BookingPopups = ({ bookingData, setShowModal, cancel }) => {
  const navigate = useNavigate();
  const userDetailsRedux = useSelector((state) => state.userDetails.apiData);

  const [headerOptionEl, setHeaderOptionEl] = useState(null);
  const [sharableLink, setSharableLink] = useState("");

  const optionState = Boolean(headerOptionEl);
  const handleClick = (event) => {
    const Option = event.currentTarget.querySelector("img");
    setHeaderOptionEl(Option);
  };
  const handleClose = () => {
    setHeaderOptionEl(null);
  };

  const onClickCancelHandler = () => {
    handleClose();
    setShowModal(true);
  };

  const handleShare = () => {
    const link = `${window.location.origin}/booking/${bookingData.bookingId}/${userDetailsRedux.userDetails.id}/${bookingData.slot.restaurant.id}`;
    copy(link);
    setSharableLink(link);
    handleClose();
  };

  console.log(
    userDetailsRedux.userDetails.id,
    bookingData.slot.restaurant.id,
    "PROFILE INFORMATION",
  );

  return (
    <div className="z-3">
      <IconButton
        id="pop-2option"
        aria-controls={optionState ? "header-section" : undefined}
        aria-haspopup="true"
        aria-expanded={optionState ? "true" : undefined}
        onClick={handleClick}
        disableRipple
        className="p-2"
      >
        <img src={OptionIcon} className="pb-2" />
      </IconButton>

      <Menu
        onKeyDownCapture={(e) => e.stopPropagation()}
        anchorEl={headerOptionEl}
        open={optionState}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="pop-up-class-2"
      >
        <div className=" rounded-5 d-flex flex-column justify-content-center p-0 w-fit pop-up-2">
          <div>
            <button
              className="m-2 d-flex justify-content-center align-items-center booking-popup-button"
              onClick={handleShare}
            >
              Share Booking
            </button>
            {bookingData.status === "CANCELLED" ? (
              ""
            ) : (
              <button
                onClick={onClickCancelHandler}
                className="m-2 d-flex justify-content-center align-items-center booking-popup-button"
              >
                Cancel Booking
              </button>
            )}
          </div>
        </div>
      </Menu>
    </div>
  );
};
