import { web_url } from "./web_url";

export const LOGIN_API = {
  LOGIN: "/v1/connectUsers/login",
  OTP_VERIFICATION: "/v1/connectUsers/validateOtp",
};
export const ORDER_APIS = {
  GET_ORDER_ID: "/v1/connectUsers/createOrder",
};

export const ORDER_SUCCESS_API = {
  ORDER_SUCCESS: "/v1/connectUsers/confirmOrder",
};
export const TRANSACTIONS_API = {
  TRANSACTIONS: "/v1/connectUsers/transactions",
  WALLET_TRANSACTIONS: "/v1/connectUsers/walletTransactions",
};
export const CURRENT_BALANCE_API = {
  CURRENT_BALANCE: "/v1/connectUsers/balance",
};
export const WEEKLY_EXPENSES_API = {
  WEEKLY_EXPENSES: "/v1/connectUsers/expenses",
};
export const EXPENSES_GRAPH_API = {
  EXPENSES_GRAPH: "/v1/connectUsers/expenseGraph",
};
export const MENU_API = {
  MENU: "/v1/connectUsers/menu/",
  EDIT_CATEGORY: "/v1/connectUsers/editCategory",
  EDIT_SUBCATEGORY: "/v1/connectUsers/editSubCategory",
  EDIT_ITEMS: "/v1/connectUsers/editItems",
  ADD_CATEGORY: "/v1/connectUsers/addCategory",
  CHANGE_PICTURE: "/v1/connectUsers/changePictures",
  CHANGE_VIDEO: "/v1/connectUsers/changeVideos",
  ADD_SUBCATEGORY: "/v1/connectUsers/addSubCategory",
  ADD_ITEM: "/v1/connectUsers/addItems",
  ADD_BUSINESS_NAME: "/v1/connectUsers/addBusinessName",
  ADD_HOMEPAGEIMAGES: "/v1/connectUsers/addHomePageImages",
  ADD_ICON: "/v1/connectUsers/addIcon",
  DELETE: "/v1/connectUsers/deleteMenu",
  ADD_EXCEL: "/v1/MenuFile",
  UPDATE_PUBLISH_STATUS: "/v1/connectUsers/updatePublishStatus",
  GET_BUSINESS_PROFILE: "/v1/connectUsers/socialProfiles",
  GET_BUSINESS_USERS: "/v1/connectUsers/checkProfileUserNameAvailabilty",
  CREATE_BUSINESS_PROFILE: "/v1/connectUsers/createSocialProfile",
  GET_USERS_NAME: "/v1/connectUsers/checkUserNameAvailability",
  GET_USERS_PROFILE: "/v1/connectUsers/getUserProfile/",
  UPDATE_PROFILE: "/v1/connectUsers/updateProfile",
  BUSINESS_USERS_PROFILE: "/v1/connectUsers/businessUserProfiles",
  ADD_NEW_USER: "/v1/connectUsers/addNewUser",
  EDIT_USER: "/v1/connectUsers/editUser",
  DELETE_USER: "/v1/connectUsers/deleteUser",
  GET_ALL_CATEGORIES: "/v1/connectUsers/getAllUserCategory",
  GET_SOCIAL_PROFILE: "/v1/connectUsers/getSocialProfile",
  GET_BUSINESS_PROFILES: "/v1/connectUsers/businessProfiles",
  EDIT_PROFILE: "/v1/connectUsers/EditProfile",
  CREATE_CATEGORY: "/v1/connectUsers/createCategory",
  DOWNLOAD_MENU_AS_EXCEL: "/v1/connectUsers/download-menu-as-excel",
  DOWNLOAD_MENU_V3_AS_EXCEL: "/v1/connectUsers/download-menu-v3-as-excel",
  MENU_DRAG_DROP: "/v1/connectUsers/menu-drag",
  MENU_ACCESS_ENDPOINT: "/v1/connectUsers/check-menu-access",
  FETCH_MENU_THEME: "v1/connectUsers/fetch-menu-theme",
  UPDATE_MENU_THEME: "v1/connectUsers/update-menu-theme",
  CREATE_STORIES: "/v1/uploadStory/stories",
  CHANGE_PRICE: "special-routes/update-price",
  TIMING_CHANGE: "special-routes/specify-category-time",
  UPLOAD_FILE: "/v1/uploadStory/upload/file",
  GET_STORIES: "/v1/uploadStory/getstory",
  DELETE_STORIES: "/v1/uploadStory/deletestory",
};
export const WALLET_SERVICES_API = {
  GET_MENU_PLAN_DETAILS: "/v1/connectUsers/getMenuPlans",
  ENABLE_FAST_SOCIAL_SERVICE: "/v1/connectUsers/enableFastSocialVerification",
  ENABLE_DIGITAL_MENU: "/v1/connectUsers/enableDigitalMenu",
  GET_BILL_DETAILS: "/v1/connectUsers/getBill",
};
export const AUTH_MENU_API = {
  MENU: "/v1/connectUsers/authMenu",
};

export const ADMIN_API = {
  ACTIVATE_SERVICE: "/v1/connectUsers/activate_deactivateServices",
  FETCH_ALL_PROFILES: "/fetch-all-profiles",
  DELETE_PROFILE: "/delete-expired/delete-profile",
};

export const QR_API = {
  SUBMIT: "/api/qruser/submit",
  DELETE: "/api/qruser/delete",
  FETCH: "/api/qruser/get",
  STATUS: "/api/qruser/activate",
  UPDATE: "/api/qruser/update",
};

export const ANNOUNCEMENT = {
  CREATE: "global-announcement/create",
  FETCH_ALL: "global-announcement/get-all",
  UPDATE: "global-announcement//update/:id",
  DELETE: "global-announcement/delete/:id",
};

export const ROLES = (roleId: string) => {
  if (roleId === "9f857f16-d769-4707-91c9-939d328f9985") {
    return "Admin";
  }
  if (roleId === "2bd3a7d0-0d45-4a08-9039-5e453cbc9acb") {
    return "Manager";
  }
  if (roleId === "fb691ce9-e369-4f81-a262-368a9a8e34cc") {
    return "Staff";
  }
  if (roleId === "2ccbc7c5-57f8-44fe-ac6c-4ddd0d32de55") {
    return "Accounts";
  }
  if (roleId === "Admin") {
    return "9f857f16-d769-4707-91c9-939d328f9985";
  }
  if (roleId === "Manager") {
    return "2bd3a7d0-0d45-4a08-9039-5e453cbc9acb";
  }
  if (roleId === "Staff") {
    return "fb691ce9-e369-4f81-a262-368a9a8e34cc";
  }
  if (roleId === "Accounts") {
    return "2ccbc7c5-57f8-44fe-ac6c-4ddd0d32de55";
  }
};

// export const baseURL = import.meta.env.DEV
//   ? "http://localhost:3001"
//   : "https://eatoes-test.eatoes.com";
// export const baseURL = import.meta.env.DEV ? "http://localhost:3001" : "https://eatoes-test.eatoes.com"
// export const baseURL = "https://eatoes-test.eatoes.com";
export const baseURL = "https://api-connect.eatoes.com"; //prod
// export const baseURL = "http://localhost:3001"; //local

// export const baseDeploymentUrl = "https://connect-test.web.app/";
// export const baseDeploymentUrl= "https://connect.eatoes.com/"
//export const baseDeploymentUrl = "https://eatoes-web.web.app/";
export const baseDeploymentUrl = "https://www.eatoes.com/";
