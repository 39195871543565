// @ts-nocheck
import {
  Route,
  Link,
  Routes,
  BrowserRouter,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Provider } from "react-redux";
import "./App.css";
import { Login } from "./pages/Login";
import { SignUp } from "./pages/SignUp";
import { useEffect, useState } from "react";
import LandingPage from "./pages/LandingPage";
import Dashboard from "./pages/Dashboard";
import WalletPage from "./pages/WalletPage";
import RefundCancellation from "./pages/RefundCancellation";
import ViewDemo from "./pages/ViewDemo";
import ScheduleDemo from "./pages/ScheduleDemo";
import QRCode from "qrcode.react";
import { USER } from "./constants/localStorageKeys";
import Accordian1 from "./Components/Accordian1";
import RechargeAmount from "./payment/RechargeAmount";
import Wallet from "./pages/Wallet";
import Menu from "./RestaurantMenu/Menu";
import SubCategory from "./RestaurantMenu/SubCategoryPage/SubCategory";
import PrivateRoutes from "./utils/PrivateRoutes";
import MainMenu from "./RestaurantMenu/MainMenu";
import Sidebar from "./Components/Sidebar";
import UserProfile from "./pages/UserProfile";
import BusinessProfile from "./pages/BusinessProfile";
import ExpandBusiness from "./pages/ExpandBusiness";
import CreateBusiness from "./pages/CreateBusiness";
import BusinessAccess from "./pages/BusinessAccess";
import ChooseCategoryPage from "./pages/ChooseCategoryPage";
import Terms from "./pages/T&C";
import Landing from "./Social_components/Landing";
import Event_list from "./Social_components/Event_list";
import LikePage from "./Social_components/LikePage";
import Search from "./Social_components/Search";
import AddLinksPage from "./Social_components/AddLinksPage";
import Socialcontactpage from "./Social_components/Socialcontactpage";
import Timingpage from "./Social_components/Timingpage";
import Social_profile_page from "./Social_components/Social_profile_page";
import Bio_page from "./Social_components/Bio_page";
import Socialpage from "./Social_components/Socialpage";
import Profilepage from "./Social_components/Profilepage";
import Announcementpage from "./Social_components/Announcementpage";
import AddEventsorupdatepage from "./Social_components/AddEventsorupdatepage";
import Media_page from "./Social_components/Media_page";
import Reservation_page from "./Social_components/Reservation_page";
import Tnc_page from "./Social_components/Tnc_page";
import store from "./redux/store";
import Fast_Social_routes from "./Social_components/Fast_Social_routes";
import Admin_profile from "./Social_components/Admin_profile";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Contact from "./pages/Contact";
import { event_lists, header } from "./Social_components/MockData";
import Calender from "./Social_components/Calenders";
import Calenders from "./Social_components/Calenders";
import "./styleSheets/google_fonts.css";
import { ProgressTabs } from "./tableReserveComponents/progressTabs";
import { TableReserveIndex } from "./tableReserveComponents";
import { DateSelect } from "./tableReserveComponents/selectComponents/dateSelect";
import { Diners } from "./tableReserveComponents/selectComponents/diners";
import { TimeSelect } from "./tableReserveComponents/selectComponents/timeSelect";
import { BookingIndex } from "./tableReserveComponents/bookings";
import FastSocialVerification from "./pages/FastSocialVerification";
import QuickAccess from "./pages/QuickAccess";
import DigitalMenuPlans from "./pages/DigitalMenuPlans";
import Document1 from "./Components/Document";
import MenuActivateScreen from "./pages/MenuActivateScreen";
import AddUser from "./pages/AddUser";
import { userOptions } from "./Social_components/MockData";
import { getbusinessProfiles } from "./API/authAPIs";
import { MENU_API, baseURL } from "./constants/Api";
import { Verify } from "./pages/Verify";
import SubCategory3 from "./menuTheme3/SubCategoryPage/SubCategory";
import TempParent3 from "./menuTheme3/TempParent";

import SubCategory2 from "./menuTheme2/SubCategoryPage/SubCategory";
import TempParent from "./menuTheme2/TempParent";
import AllProfile from "./Social_components/AllProfiles";
import ExternalRedirect from "./pages/RedirectToExternal";
import Communication from "./All_services.tsx/ClearCommunication";
import ProfileList from "./AdminAccount/ProfileList";
import Form from "./pages/AddQRUser";
import EditUser from "./pages/EditUser";
import QRUserList from "./pages/GetQRUser";
// // import { Menu } from '@mui/material';

import ReservationLayout from "./Components/ReservationLayout/ReservationLayout";
import TableReservation from "./pages/reservation/TableReservation";
import PricingPage from "./pages/reservation/PricingPage";
import ListPage from "./pages/reservation/ListPage";
import CreateRestaurant from "./pages/reservation/CreateRestaurant";
import ListPageID from "./pages/reservation/ListPageID";
import SlotsManagement from "./pages/reservation/SlotsManagement";
import WorkingHoursManagement from "./pages/reservation/WorkingHoursManagement";
import Profile from "./pages/reservation/Profile";
import BookingManagement from "./pages/reservation/BookingManagement";
import TermsAndConditions from "./pages/reservation/TermsAndConditions";
import Cart from "./RestaurantMenu/ItemsSection/cart";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4";
import QRupdateUser from "./pages/QRupdateUser";
import Feed from "./pages/Feed";
import ProfilePage from "./pages/ProfilePage";
import IndividualBooking from "./tableReserveComponents/bookings/IndividualBooking";

function App(data) {
  const [documentStatus, setDocumentStatus] = useState(false);
  const [profile, setProfile] = useState([]);
  const [profileCreated, setProfileCreated] = useState(false);
  const [fetchProfile, setFetchProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem(USER.JWT_ACCESS_TOKEN);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!token) return;

    setLoading(true);
    getbusinessProfiles(`${baseURL}${MENU_API.GET_BUSINESS_PROFILE}`)
      .then((data) => {
        setProfile(data);
        console.log(data, "dataaa");
      })
      .finally(() => setLoading(false));
  }, [token, profileCreated, fetchProfile]);

  useEffect(() => {
    ReactGA.initialize(import.meta.env.VITE_GOOGLE_TRACKING_ID);
  }, []);
  return (
    <Provider store={store}>
      <div>
        <BrowserRouter>
          <Routes>
            <Route
              path="/*"
              element={
                <Dashboard
                  profile={profile}
                  setProfile={setProfile}
                  fetchProfile={fetchProfile}
                  setFetchProfile={setFetchProfile}
                  loading={loading}
                  setLoading={setLoading}
                />
              }
            />
            <Route element={<PrivateRoutes />}>
              <Route path="/RechargeAmount" element={<RechargeAmount />} />
              <Route path="/menu/edit/:name1" element={<MainMenu />} />
              {/* <Route path="edit/menu/:name1" element={<MainMenu />} /> */}
              <Route path="menu/edit/SubCategory" element={<SubCategory />} />
              <Route path="/UpdateProfile" element={<UserProfile />} />

              <Route path="/allprofiles" element={<AllProfile />} />
              <Route path="/BusinessProfile" element={<ExpandBusiness />} />
              <Route
                path="/CreateBusiness"
                element={
                  <CreateBusiness
                    s
                    profileCreated={profileCreated}
                    setProfileCreated={setProfileCreated}
                  />
                }
              />
              <Route path="/BusinessAccess" element={<BusinessAccess />} />
              <Route path="/ChooseCategory" element={<ChooseCategoryPage />} />
              <Route path="/FastSocial/*" element={<Fast_Social_routes />} />

              <Route
                path="/reservation"
                element={
                  <ReservationLayout>
                    <TableReservation />
                  </ReservationLayout>
                }
              />
              <Route
                path="/reservation/pricing"
                element={
                  <ReservationLayout>
                    <PricingPage />
                  </ReservationLayout>
                }
              />
              <Route
                path="/reservation/manage-slots"
                element={
                  <ReservationLayout>
                    <SlotsManagement />
                  </ReservationLayout>
                }
              />

              <Route
                path="/reservation/manage-working-hours"
                element={
                  <ReservationLayout>
                    <WorkingHoursManagement />
                  </ReservationLayout>
                }
              />

              <Route
                path="/reservation/terms-and-conditions"
                element={
                  <ReservationLayout>
                    <TermsAndConditions />
                  </ReservationLayout>
                }
              />

              <Route
                path="/reservation/list/i/:id"
                element={
                  <ReservationLayout>
                    <ListPageID />
                  </ReservationLayout>
                }
              />
              <Route
                path="/reservation/list"
                element={
                  <ReservationLayout>
                    <ListPage />
                  </ReservationLayout>
                }
              />
              <Route
                path="/reservation/createRestaurant"
                element={
                  <ReservationLayout>
                    <CreateRestaurant />
                  </ReservationLayout>
                }
              />
              <Route
                path="/reservation/profile"
                element={
                  <ReservationLayout>
                    <Profile />
                  </ReservationLayout>
                }
              />
              <Route
                path="/reservation/bookings"
                element={
                  <ReservationLayout>
                    <BookingManagement />
                  </ReservationLayout>
                }
              />

              <Route
                path="/FastSocialVerification"
                element={<FastSocialVerification />}
              />
              <Route path="/Adduser" element={<AddUser />} />
              <Route path="/edit-user" element={<EditUser />} />
              <Route
                path="/QuickAccess"
                element={
                  <QuickAccess profile={profile} setProfiles={setProfile} />
                }
              />
              <Route path="/DigitalMenuPlans" element={<DigitalMenuPlans />} />
              <Route path="/Pdf" element={<Document1 />} />
              <Route
                path="/DigitalMenuActivation"
                element={<MenuActivateScreen />}
              />
            </Route>
            <Route path="/Menu" element={<MainMenu />} />
            <Route path="/Home" element={<LandingPage />} />
            <Route
              path="/Login"
              element={
                <Login
                  fetchProfile={fetchProfile}
                  setFetchProfile={setFetchProfile}
                />
              }
            />
            <Route
              path="/SignUp"
              element={
                <SignUp
                  fetchProfile={fetchProfile}
                  setFetchProfile={setFetchProfile}
                />
              }
            />
            <Route
              path="/menu/v2/*"
              element={
                <TempParent // Remaining or Not Working
                  profile={profile}
                  setProfile={setProfile}
                  fetchProfile={fetchProfile}
                  setFetchProfile={setFetchProfile}
                  loading={loading}
                  setLoading={setLoading}
                />
              }
            />

            <Route
              path="/menu/v3/*"
              element={
                <TempParent3
                  profile={profile}
                  setProfile={setProfile}
                  fetchProfile={fetchProfile}
                  setFetchProfile={setFetchProfile}
                  loading={loading}
                  setLoading={setLoading}
                />
              }
            />

            <Route path="menu/v2/SubCategory" element={<SubCategory2 />} />

            <Route path="menu/v3/SubCategory" element={<SubCategory3 />} />
            <Route path="/ViewDemo" element={<ViewDemo />} />
            <Route path="/ScheduleDemo" element={<ScheduleDemo />} />
            <Route path="/Accordian" element={<Accordian1 />} />
            {/* <Route path="/menu/:name1" element={<MainMenu />} /> */}
            {/* <Route path="menu/SubCategory" element={<SubCategory />} /> */}
            <Route
              path="/adminprofile"
              element={<Admin_profile data={userOptions} />}
            />
            <Route path="/allprofiles" element={<AllProfile />} />
            <Route
              path="/refund-policy"
              element={<RefundCancellation hideIcon={true} />}
            />
            <Route
              path="/terms-and-conditions"
              element={<Terms hideIcon={true} />}
            />
            <Route
              path="/privacy-policy"
              element={<PrivacyPolicy hideIcon={true} />}
            />
            <Route path="/contact" element={<Contact hideIcon={true} />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="reserve/:uniqueId/*" element={<TableReserveIndex />} />
            <Route path="/my-bookings" element={<BookingIndex />} />
            {/* <Route path="/fetch-bookings" element */}
            <Route path="/menu/:name1" element={<MainMenu />} />
            <Route path="menu/SubCategory" element={<SubCategory />} />
            <Route path="/communication" element={<Communication />} />
            <Route path="/eatoesAllaccounts" element={<ProfileList />} />
            <Route path="/AddQRUser" element={<Form />} />
            <Route path="/updateQRuser" element={<QRupdateUser />} />
            <Route path="/QRUserProfile" element={<QRUserList />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/feed" element={<Feed />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route
              path="/booking/:bookingId/:userId/:restaurantId"
              element={<IndividualBooking />}
            />
          </Routes>
        </BrowserRouter>
      </div>
      <ToastContainer />
    </Provider>
  );
}

export default App;
