import { useState, useEffect } from "react";
import Icons from "../../Components/Icons";
import SlotItem from "../../Components/SlotItem";
import Datepicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { baseURL } from "../../constants/Api";
import {
  FloatingContainer,
  FloatingInput,
} from "../../Components/FloatingInput";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css";
import "react-clock/dist/Clock.css";
import { FaRegCalendarAlt } from "react-icons/fa";

import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import leftArrow from "../../assets/images/left-arrow.svg";
import rightArrow from "../../assets/images/right-arrow.svg";
import AnalyticsUtil from "../../utils/GoogleAnalytics";

const SlotsManagement = () => {
  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView(
      "/reservation/manage-slots",
      "ReservationManageSlots Page",
    );
  }, []);

  const [date, setDate] = useState(new Date());
  const [numOfBookedSlots, setNumOfBookedSlots] = useState(0);
  const [restaurantId, setRestaurantId] = useState("");

  const [slots, setSlots] = useState([]);

  const [mondayStartHour, setMondayStartHour] = useState("10");
  const [mondayStartMinute, setMondayStartMinute] = useState("00");
  const [mondayStartAMPM, setMondayStartAMPM] = useState("AM");
  const [mondayEndHour, setMondayEndHour] = useState("11");
  const [mondayEndMinute, setMondayEndMinute] = useState("00");
  const [mondayEndAMPM, setMondayEndAMPM] = useState("PM");

  const [tuesdayStartHour, setTuesdayStartHour] = useState("10");
  const [tuesdayStartMinute, setTuesdayStartMinute] = useState("00");
  const [tuesdayStartAMPM, setTuesdayStartAMPM] = useState("AM");
  const [tuesdayEndHour, setTuesdayEndHour] = useState("11");
  const [tuesdayEndMinute, setTuesdayEndMinute] = useState("00");
  const [tuesdayEndAMPM, setTuesdayEndAMPM] = useState("PM");

  const [wednesdayStartHour, setWednesdayStartHour] = useState("10");
  const [wednesdayStartMinute, setWednesdayStartMinute] = useState("00");
  const [wednesdayStartAMPM, setWednesdayStartAMPM] = useState("AM");
  const [wednesdayEndHour, setWednesdayEndHour] = useState("11");
  const [wednesdayEndMinute, setWednesdayEndMinute] = useState("00");
  const [wednesdayEndAMPM, setWednesdayEndAMPM] = useState("PM");

  const [thursdayStartHour, setThursdayStartHour] = useState("10");
  const [thursdayStartMinute, setThursdayStartMinute] = useState("00");
  const [thursdayStartAMPM, setThursdayStartAMPM] = useState("AM");
  const [thursdayEndHour, setThursdayEndHour] = useState("11");
  const [thursdayEndMinute, setThursdayEndMinute] = useState("00");
  const [thursdayEndAMPM, setThursdayEndAMPM] = useState("PM");

  const [fridayStartHour, setFridayStartHour] = useState("10");
  const [fridayStartMinute, setFridayStartMinute] = useState("00");
  const [fridayStartAMPM, setFridayStartAMPM] = useState("AM");
  const [fridayEndHour, setFridayEndHour] = useState("11");
  const [fridayEndMinute, setFridayEndMinute] = useState("00");
  const [fridayEndAMPM, setFridayEndAMPM] = useState("PM");

  const [saturdayStartHour, setSaturdayStartHour] = useState("10");
  const [saturdayStartMinute, setSaturdayStartMinute] = useState("00");
  const [saturdayStartAMPM, setSaturdayStartAMPM] = useState("AM");
  const [saturdayEndHour, setSaturdayEndHour] = useState("11");
  const [saturdayEndMinute, setSaturdayEndMinute] = useState("00");
  const [saturdayEndAMPM, setSaturdayEndAMPM] = useState("PM");

  const [sundayStartHour, setSundayStartHour] = useState("10");
  const [sundayStartMinute, setSundayStartMinute] = useState("00");
  const [sundayStartAMPM, setSundayStartAMPM] = useState("AM");
  const [sundayEndHour, setSundayEndHour] = useState("11");
  const [sundayEndMinute, setSundayEndMinute] = useState("00");
  const [sundayEndAMPM, setSundayEndAMPM] = useState("PM");

  const hours = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const minutes = ["00", "15", "30", "45"];

  const notifyModified = () => toast("Slot Modified");
  const notifyModifiedAll = () => toast("Slots Modified");

  useEffect(() => {
    setRestaurantId(localStorage.getItem("restaurantId"));
    fetchSlots(localStorage.getItem("restaurantId"), date);
  }, [date]);

  const formatDateToYYYYMMDD = (inputDate) => {
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const setTheDate = (inputDate) => {
    // Call API to fetch slots of that date
    fetchSlots(restaurantId, inputDate);

    setDate(inputDate);
  };

  const changeToPrevDate = () => {
    const prevDate = new Date(date);
    prevDate.setDate(prevDate.getDate() - 1);
    setDate(prevDate);

    fetchSlots(localStorage.getItem("restaurantId"), prevDate);
  };

  const changeToNextDate = () => {
    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    setDate(nextDate);

    fetchSlots(localStorage.getItem("restaurantId"), nextDate);
  };

  const formattedDate = date.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  const copyToAll = async () => {
    // Make all fields same as Monday (in state values)

    setTuesdayStartHour(mondayStartHour);
    setTuesdayStartMinute(mondayStartMinute);
    setTuesdayStartAMPM(mondayStartAMPM);

    setWednesdayStartHour(mondayStartHour);
    setWednesdayStartMinute(mondayStartMinute);
    setWednesdayStartAMPM(mondayStartAMPM);

    setThursdayStartHour(mondayStartHour);
    setThursdayStartMinute(mondayStartMinute);
    setThursdayStartAMPM(mondayStartAMPM);

    setFridayStartHour(mondayStartHour);
    setFridayStartMinute(mondayStartMinute);
    setFridayStartAMPM(mondayStartAMPM);

    setSaturdayStartHour(mondayStartHour);
    setSaturdayStartMinute(mondayStartMinute);
    setSaturdayStartAMPM(mondayStartAMPM);

    setSundayStartHour(mondayStartHour);
    setSundayStartMinute(mondayStartMinute);
    setSundayStartAMPM(mondayStartAMPM);

    const theStartVal = convertToHHMM(
      mondayStartHour,
      mondayStartMinute,
      mondayStartAMPM,
    );
    const theEndVal = convertToHHMM(
      mondayEndHour,
      mondayEndMinute,
      mondayEndAMPM,
    );

    // Call API to set
    if (restaurantId !== "") {
      try {
        const { data } = await axios.post(
          `${baseURL}/v1/restaurant/updateRestaurantAllDayWorkingHour`,
          {
            restaurantId: restaurantId,
            day: "mon",
            startTime: theStartVal,
            endTime: theEndVal,
          },
        );

        // Handle the response data as needed
        //setResponse(data);

        notifyModifiedAll();
      } catch (error) {
        // Handle error, e.g., display an error message
        console.error("Error making POST request:", error);
      }
    }
  };

  const updateMon = async (val) => {
    if (val[0] !== null && val[1] !== null && val[0] !== "" && val[1] !== "") {
      updateMondayHours(val);
    }
  };

  const updateTue = async (val) => {
    if (val[0] !== null && val[1] !== null && val[0] !== "" && val[1] !== "") {
      updateTuesdayHours(val);
    }
  };

  const updateWed = async (val) => {
    if (val[0] !== null && val[1] !== null && val[0] !== "" && val[1] !== "") {
      updateWednesdayHours(val);
    }
  };

  const updateThu = async (val) => {
    if (val[0] !== null && val[1] !== null && val[0] !== "" && val[1] !== "") {
      updateThursdayHours(val);
    }
  };

  const updateFri = async (val) => {
    if (val[0] !== null && val[1] !== null && val[0] !== "" && val[1] !== "") {
      updateFridayHours(val);
    }
  };

  const updateSat = async (val) => {
    if (val[0] !== null && val[1] !== null && val[0] !== "" && val[1] !== "") {
      updateSaturdayHours(val);
    }
  };

  const updateSun = async (val) => {
    if (val[0] !== null && val[1] !== null && val[0] !== "" && val[1] !== "") {
      updateSundayHours(val);
    }
  };

  const hardcodedSlots = [
    "00:00",
    "00:15",
    "00:30",
    "00:45",
    "01:00",
    "01:15",
    "01:30",
    "01:45",
    "02:00",
    "02:15",
    "02:30",
    "02:45",
    "03:00",
    "03:15",
    "03:30",
    "03:45",
    "04:00",
    "04:15",
    "04:30",
    "04:45",
    "05:00",
    "05:15",
    "05:30",
    "05:45",
    "06:00",
    "06:15",
    "06:30",
    "06:45",
    "07:00",
    "07:15",
    "07:30",
    "07:45",
    "08:00",
    "08:15",
    "08:30",
    "08:45",
    "09:00",
    "09:15",
    "09:30",
    "09:45",
    "10:00",
    "10:15",
    "10:30",
    "10:45",
    "11:00",
    "11:15",
    "11:30",
    "11:45",
    "12:00",
    "12:15",
    "12:30",
    "12:45",
    "13:00",
    "13:15",
    "13:30",
    "13:45",
    "14:00",
    "14:15",
    "14:30",
    "14:45",
    "15:00",
    "15:15",
    "15:30",
    "15:45",
    "16:00",
    "16:15",
    "16:30",
    "16:45",
    "17:00",
    "17:15",
    "17:30",
    "17:45",
    "18:00",
    "18:15",
    "18:30",
    "18:45",
    "19:00",
    "19:15",
    "19:30",
    "19:45",
    "20:00",
    "20:15",
    "20:30",
    "20:45",
    "21:00",
    "21:15",
    "21:30",
    "21:45",
    "22:00",
    "22:15",
    "22:30",
    "22:45",
    "23:00",
    "23:15",
    "23:30",
    "23:45",
  ];

  const getWorkingHoursForCurrentDay = () => {
    const currentDay = date.getDay();

    switch (currentDay) {
      case 0: {
        const theStartVal = convertToHHMM(
          mondayStartHour,
          mondayStartMinute,
          mondayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          mondayEndHour,
          mondayEndMinute,
          mondayEndAMPM,
        );
        return [theStartVal, theEndVal];
      }
      case 1: {
        const theStartVal = convertToHHMM(
          tuesdayStartHour,
          tuesdayStartMinute,
          tuesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          tuesdayEndHour,
          tuesdayEndMinute,
          tuesdayEndAMPM,
        );
        return [theStartVal, theEndVal];
      }
      case 2: {
        const theStartVal = convertToHHMM(
          wednesdayStartHour,
          wednesdayStartMinute,
          wednesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          wednesdayEndHour,
          wednesdayEndMinute,
          wednesdayEndAMPM,
        );
        return [theStartVal, theEndVal];
      }
      case 3: {
        const theStartVal = convertToHHMM(
          thursdayStartHour,
          thursdayStartMinute,
          thursdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          thursdayEndHour,
          thursdayEndMinute,
          thursdayEndAMPM,
        );
        return [theStartVal, theEndVal];
      }
      case 4: {
        const theStartVal = convertToHHMM(
          fridayStartHour,
          fridayStartMinute,
          fridayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          fridayEndHour,
          fridayEndMinute,
          fridayEndAMPM,
        );
        return [theStartVal, theEndVal];
      }
      case 5: {
        const theStartVal = convertToHHMM(
          saturdayStartHour,
          saturdayStartMinute,
          saturdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          saturdayEndHour,
          saturdayEndMinute,
          saturdayEndAMPM,
        );
        return [theStartVal, theEndVal];
      }
      case 6: {
        const theStartVal = convertToHHMM(
          sundayStartHour,
          sundayStartMinute,
          sundayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          sundayEndHour,
          sundayEndMinute,
          sundayEndAMPM,
        );
        return [theStartVal, theEndVal];
      }

      default:
        return [];
    }
  };

  const workingHoursForCurrentDay = getWorkingHoursForCurrentDay();

  const getDayOfWeek = (date) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayIndex = date.getDay();
    return daysOfWeek[dayIndex];
  };

  const filteredSlots = [];

  for (let i = 0; i < hardcodedSlots.length; i++) {
    // Check each item in this array (e.g. "06:00") and see if this slot should be shown

    const hrValue = +hardcodedSlots[i].split(":")[0];

    const theDay = getDayOfWeek(date);

    if (theDay === "Monday") {
      const theStartVal = convertToHHMM(
        mondayStartHour,
        mondayStartMinute,
        mondayStartAMPM,
      );
      const theEndVal = convertToHHMM(
        mondayEndHour,
        mondayEndMinute,
        mondayEndAMPM,
      );
      const mondayHours = [theStartVal, theEndVal];

      const theStartHr = +mondayHours[0].split(":")[0];
      const theEndHr = +mondayHours[1].split(":")[0];

      if (hrValue >= theStartHr && hrValue < theEndHr) {
        filteredSlots.push(hardcodedSlots[i]);
      }
    } else if (theDay === "Tuesday") {
      const theStartVal = convertToHHMM(
        tuesdayStartHour,
        tuesdayStartMinute,
        tuesdayStartAMPM,
      );
      const theEndVal = convertToHHMM(
        tuesdayEndHour,
        tuesdayEndMinute,
        tuesdayEndAMPM,
      );
      const tuesdayHours = [theStartVal, theEndVal];

      const theStartHr = +tuesdayHours[0].split(":")[0];
      const theEndHr = +tuesdayHours[1].split(":")[0];

      if (hrValue >= theStartHr && hrValue < theEndHr) {
        filteredSlots.push(hardcodedSlots[i]);
      }
    } else if (theDay === "Wednesday") {
      const theStartVal = convertToHHMM(
        wednesdayStartHour,
        wednesdayStartMinute,
        wednesdayStartAMPM,
      );
      const theEndVal = convertToHHMM(
        wednesdayEndHour,
        wednesdayEndMinute,
        wednesdayEndAMPM,
      );
      const wednesdayHours = [theStartVal, theEndVal];

      const theStartHr = +wednesdayHours[0].split(":")[0];
      const theEndHr = +wednesdayHours[1].split(":")[0];

      if (hrValue >= theStartHr && hrValue < theEndHr) {
        filteredSlots.push(hardcodedSlots[i]);
      }
    } else if (theDay === "Thursday") {
      const theStartVal = convertToHHMM(
        thursdayStartHour,
        thursdayStartMinute,
        thursdayStartAMPM,
      );
      const theEndVal = convertToHHMM(
        thursdayEndHour,
        thursdayEndMinute,
        thursdayEndAMPM,
      );
      const thursdayHours = [theStartVal, theEndVal];

      const theStartHr = +thursdayHours[0].split(":")[0];
      const theEndHr = +thursdayHours[1].split(":")[0];

      if (hrValue >= theStartHr && hrValue < theEndHr) {
        filteredSlots.push(hardcodedSlots[i]);
      }
    } else if (theDay === "Friday") {
      const theStartVal = convertToHHMM(
        fridayStartHour,
        fridayStartMinute,
        fridayStartAMPM,
      );
      const theEndVal = convertToHHMM(
        fridayEndHour,
        fridayEndMinute,
        fridayEndAMPM,
      );
      const fridayHours = [theStartVal, theEndVal];

      const theStartHr = +fridayHours[0].split(":")[0];
      const theEndHr = +fridayHours[1].split(":")[0];

      if (hrValue >= theStartHr && hrValue < theEndHr) {
        filteredSlots.push(hardcodedSlots[i]);
      }
    } else if (theDay === "Saturday") {
      const theStartVal = convertToHHMM(
        saturdayStartHour,
        saturdayStartMinute,
        saturdayStartAMPM,
      );
      const theEndVal = convertToHHMM(
        saturdayEndHour,
        saturdayEndMinute,
        saturdayEndAMPM,
      );
      const saturdayHours = [theStartVal, theEndVal];

      const theStartHr = +saturdayHours[0].split(":")[0];
      const theEndHr = +saturdayHours[1].split(":")[0];

      if (hrValue >= theStartHr && hrValue < theEndHr) {
        filteredSlots.push(hardcodedSlots[i]);
      }
    } else if (theDay === "Sunday") {
      const theStartVal = convertToHHMM(
        sundayStartHour,
        sundayStartMinute,
        sundayStartAMPM,
      );
      const theEndVal = convertToHHMM(
        sundayEndHour,
        sundayEndMinute,
        sundayEndAMPM,
      );
      const sundayHours = [theStartVal, theEndVal];

      const theStartHr = +sundayHours[0].split(":")[0];
      const theEndHr = +sundayHours[1].split(":")[0];

      if (hrValue >= theStartHr && hrValue < theEndHr) {
        filteredSlots.push(hardcodedSlots[i]);
      }
    }
  }

  function parseHHMM(time) {
    const [hourStr, minuteStr] = time.split(":");

    const hour = +hourStr;
    const minute = +minuteStr;

    const ampm = hour < 12 ? "AM" : "PM";

    const adjustedHour = (hour % 12 || 12).toString().padStart(2, "0");

    const adjustedMinute = minute.toString().padStart(2, "0");

    return { hour: adjustedHour, minute: adjustedMinute, ampm };
  }

  const fetchSlots = async (restroId, theDate) => {
    if (restroId !== "") {
      try {
        // const apiUrl = `${baseURL}/v1/restaurant/fetchRestaurantSlots/${restroId}/${formatDateToYYYYMMDD(theDate)}`;
        const apiUrl = `${baseURL}/v1/restaurant/fetchUnAvailableRestaurantSlots/${restroId}/${formatDateToYYYYMMDD(theDate)}`;

        const response = await fetch(apiUrl);

        if (!response.ok) {
          throw new Error(`Failed to fetch slots. Status: ${response.status}`);
        }

        const data = await response.json();

        console.log("data:", data);

        setSlots(data.slots || []);

        if (data.restaurant.mondayStartTime && data.restaurant.mondayEndTime) {
          // setMondayHours([data.restaurant.mondayStartTime, data.restaurant.mondayEndTime]);

          const {
            hour: startHour,
            minute: startMinute,
            ampm: startAMPM,
          } = parseHHMM(data.restaurant.mondayStartTime);
          const {
            hour: endHour,
            minute: endMinute,
            ampm: endAMPM,
          } = parseHHMM(data.restaurant.mondayEndTime);

          setMondayStartHour(startHour);
          setMondayStartMinute(startMinute);
          setMondayStartAMPM(startAMPM);

          setMondayEndHour(endHour);
          setMondayEndMinute(endMinute);
          setMondayEndAMPM(endAMPM);
        }

        if (
          data.restaurant.tuesdayStartTime &&
          data.restaurant.tuesdayEndTime
        ) {
          // setTuesdayHours([data.restaurant.tuesdayStartTime, data.restaurant.tuesdayEndTime]);

          const {
            hour: startHour,
            minute: startMinute,
            ampm: startAMPM,
          } = parseHHMM(data.restaurant.tuesdayStartTime);
          const {
            hour: endHour,
            minute: endMinute,
            ampm: endAMPM,
          } = parseHHMM(data.restaurant.tuesdayEndTime);

          setTuesdayStartHour(startHour);
          setTuesdayStartMinute(startMinute);
          setTuesdayStartAMPM(startAMPM);

          setTuesdayEndHour(endHour);
          setTuesdayEndMinute(endMinute);
          setTuesdayEndAMPM(endAMPM);
        }

        if (
          data.restaurant.wednesdayStartTime &&
          data.restaurant.wednesdayEndTime
        ) {
          //setWednesdayHours([data.restaurant.wednesdayStartTime, data.restaurant.wednesdayEndTime]);

          const {
            hour: startHour,
            minute: startMinute,
            ampm: startAMPM,
          } = parseHHMM(data.restaurant.wednesdayStartTime);
          const {
            hour: endHour,
            minute: endMinute,
            ampm: endAMPM,
          } = parseHHMM(data.restaurant.wednesdayEndTime);

          setWednesdayStartHour(startHour);
          setWednesdayStartMinute(startMinute);
          setWednesdayStartAMPM(startAMPM);

          setWednesdayEndHour(endHour);
          setWednesdayEndMinute(endMinute);
          setWednesdayEndAMPM(endAMPM);
        }

        if (
          data.restaurant.thursdayStartTime &&
          data.restaurant.thursdayEndTime
        ) {
          //setThursdayHours([data.restaurant.thursdayStartTime, data.restaurant.thursdayEndTime]);

          const {
            hour: startHour,
            minute: startMinute,
            ampm: startAMPM,
          } = parseHHMM(data.restaurant.thursdayStartTime);
          const {
            hour: endHour,
            minute: endMinute,
            ampm: endAMPM,
          } = parseHHMM(data.restaurant.thursdayEndTime);

          setThursdayStartHour(startHour);
          setThursdayStartMinute(startMinute);
          setThursdayStartAMPM(startAMPM);

          setThursdayEndHour(endHour);
          setThursdayEndMinute(endMinute);
          setThursdayEndAMPM(endAMPM);
        }

        if (data.restaurant.fridayStartTime && data.restaurant.fridayEndTime) {
          //setFridayHours([data.restaurant.fridayStartTime, data.restaurant.fridayEndTime]);

          const {
            hour: startHour,
            minute: startMinute,
            ampm: startAMPM,
          } = parseHHMM(data.restaurant.fridayStartTime);
          const {
            hour: endHour,
            minute: endMinute,
            ampm: endAMPM,
          } = parseHHMM(data.restaurant.fridayEndTime);

          setFridayStartHour(startHour);
          setFridayStartMinute(startMinute);
          setFridayStartAMPM(startAMPM);

          setFridayEndHour(endHour);
          setFridayEndMinute(endMinute);
          setFridayEndAMPM(endAMPM);
        }

        if (
          data.restaurant.saturdayStartTime &&
          data.restaurant.saturdayEndTime
        ) {
          //setSaturdayHours([data.restaurant.saturdayStartTime, data.restaurant.saturdayEndTime]);

          const {
            hour: startHour,
            minute: startMinute,
            ampm: startAMPM,
          } = parseHHMM(data.restaurant.saturdayStartTime);
          const {
            hour: endHour,
            minute: endMinute,
            ampm: endAMPM,
          } = parseHHMM(data.restaurant.saturdayEndTime);

          setSaturdayStartHour(startHour);
          setSaturdayStartMinute(startMinute);
          setSaturdayStartAMPM(startAMPM);

          setSaturdayEndHour(endHour);
          setSaturdayEndMinute(endMinute);
          setSaturdayEndAMPM(endAMPM);
        }

        if (
          data.restaurant.sundayStartTime &&
          data.restaurant.sundayStartTime
        ) {
          // setSundayHours([data.restaurant.sundayStartTime, data.restaurant.sundayEndTime]);

          const {
            hour: startHour,
            minute: startMinute,
            ampm: startAMPM,
          } = parseHHMM(data.restaurant.sundayStartTime);
          const {
            hour: endHour,
            minute: endMinute,
            ampm: endAMPM,
          } = parseHHMM(data.restaurant.sundayEndTime);

          setSundayStartHour(startHour);
          setSundayStartMinute(startMinute);
          setSundayStartAMPM(startAMPM);

          setSundayEndHour(endHour);
          setSundayEndMinute(endMinute);
          setSundayEndAMPM(endAMPM);
        }
      } catch (error) {
        console.error("Error fetching slots:", error.message);
      }
    }
  };

  const updateMondayHours = async (startTime, endTime) => {
    if (restaurantId !== "") {
      try {
        const { data } = await axios.post(
          `${baseURL}/v1/restaurant/updateRestaurantWorkingHour`,
          {
            restaurantId: restaurantId,
            day: "mon",
            startTime: startTime,
            endTime: endTime,
          },
        );

        // Handle the response data as needed
        //setResponse(data);
      } catch (error) {
        // Handle error, e.g., display an error message
        console.error("Error making POST request:", error);
      }
    }
  };

  const updateTuesdayHours = async (startTime, endTime) => {
    if (restaurantId !== "") {
      try {
        const { data } = await axios.post(
          `${baseURL}/v1/restaurant/updateRestaurantWorkingHour`,
          {
            restaurantId: restaurantId,
            day: "tue",
            startTime: startTime,
            endTime: endTime,
          },
        );

        // Handle the response data as needed
        //setResponse(data);
      } catch (error) {
        // Handle error, e.g., display an error message
        console.error("Error making POST request:", error);
      }
    }
  };

  const updateWednesdayHours = async (startTime, endTime) => {
    if (restaurantId !== "") {
      try {
        const { data } = await axios.post(
          `${baseURL}/v1/restaurant/updateRestaurantWorkingHour`,
          {
            restaurantId: restaurantId,
            day: "wed",
            startTime: startTime,
            endTime: endTime,
          },
        );

        // Handle the response data as needed
        //setResponse(data);
      } catch (error) {
        // Handle error, e.g., display an error message
        console.error("Error making POST request:", error);
      }
    }
  };

  const updateThursdayHours = async (startTime, endTime) => {
    if (restaurantId !== "") {
      try {
        const { data } = await axios.post(
          `${baseURL}/v1/restaurant/updateRestaurantWorkingHour`,
          {
            restaurantId: restaurantId,
            day: "thu",
            startTime: startTime,
            endTime: endTime,
          },
        );

        // Handle the response data as needed
        //setResponse(data);
      } catch (error) {
        // Handle error, e.g., display an error message
        console.error("Error making POST request:", error);
      }
    }
  };

  const updateFridayHours = async (startTime, endTime) => {
    if (restaurantId !== "") {
      try {
        const { data } = await axios.post(
          `${baseURL}/v1/restaurant/updateRestaurantWorkingHour`,
          {
            restaurantId: restaurantId,
            day: "fri",
            startTime: startTime,
            endTime: endTime,
          },
        );

        // Handle the response data as needed
        //setResponse(data);
      } catch (error) {
        // Handle error, e.g., display an error message
        console.error("Error making POST request:", error);
      }
    }
  };

  const updateSaturdayHours = async (startTime, endTime) => {
    if (restaurantId !== "") {
      try {
        const { data } = await axios.post(
          `${baseURL}/v1/restaurant/updateRestaurantWorkingHour`,
          {
            restaurantId: restaurantId,
            day: "sat",
            startTime: startTime,
            endTime: endTime,
          },
        );

        // Handle the response data as needed
        //setResponse(data);
      } catch (error) {
        // Handle error, e.g., display an error message
        console.error("Error making POST request:", error);
      }
    }
  };

  const updateSundayHours = async (startTime, endTime) => {
    if (restaurantId !== "") {
      try {
        const { data } = await axios.post(
          `${baseURL}/v1/restaurant/updateRestaurantWorkingHour`,
          {
            restaurantId: restaurantId,
            day: "sun",
            startTime: startTime,
            endTime: endTime,
          },
        );

        // Handle the response data as needed
        //setResponse(data);
      } catch (error) {
        // Handle error, e.g., display an error message
        console.error("Error making POST request:", error);
      }
    }
  };

  const updateSlot = async (slotStatus, time) => {
    if (restaurantId !== "") {
      try {
        const { data } = await axios.post(
          `${baseURL}/v1/restaurant/updateRestaurantSlot`,
          {
            restaurantId: restaurantId,
            slotDate: formatDateToYYYYMMDD(date),
            slotStartTime: time,
            slotStatus: slotStatus,
          },
        );

        // Handle the response data as needed
        //setResponse(data);
        // notifyModified();
      } catch (error) {
        // Handle error, e.g., display an error message
        console.error("Error making POST request:", error);
      }
    }
  };

  function convertToHHMM(hhValue, mmValue, ampmValue) {
    let _hhValue = +hhValue;
    let _mmValue = +mmValue;

    if (ampmValue === "PM" && _hhValue !== 12) {
      _hhValue += 12;
    } else if (ampmValue === "AM" && _hhValue === 12) {
      _hhValue = 0;
    }

    _hhValue = _hhValue < 10 ? `0${_hhValue}` : _hhValue;
    _mmValue = _mmValue < 10 ? `0${_mmValue}` : _mmValue;

    // Return the formatted HH:mm string
    return `${_hhValue}:${_mmValue}`;
  }

  const handleHourChange = (dayOfWeek, startOrEnd, event) => {
    const selectedHour = event.target.value;

    if (dayOfWeek === "mon") {
      if (startOrEnd === "start") {
        setMondayStartHour(selectedHour);

        const theStartVal = convertToHHMM(
          selectedHour,
          mondayStartMinute,
          mondayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          mondayEndHour,
          mondayEndMinute,
          mondayEndAMPM,
        );
        updateMondayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setMondayEndHour(selectedHour);

        const theStartVal = convertToHHMM(
          mondayStartHour,
          mondayStartMinute,
          mondayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          selectedHour,
          mondayEndMinute,
          mondayEndAMPM,
        );
        updateMondayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "tue") {
      if (startOrEnd === "start") {
        setTuesdayStartHour(selectedHour);

        const theStartVal = convertToHHMM(
          selectedHour,
          tuesdayStartMinute,
          tuesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          tuesdayEndHour,
          tuesdayEndMinute,
          tuesdayEndAMPM,
        );
        updateTuesdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setTuesdayEndHour(selectedHour);

        const theStartVal = convertToHHMM(
          tuesdayStartHour,
          tuesdayStartMinute,
          tuesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          selectedHour,
          tuesdayEndMinute,
          tuesdayEndAMPM,
        );
        updateTuesdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "wed") {
      if (startOrEnd === "start") {
        setWednesdayStartHour(selectedHour);

        const theStartVal = convertToHHMM(
          selectedHour,
          wednesdayStartMinute,
          wednesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          wednesdayEndHour,
          wednesdayEndMinute,
          wednesdayEndAMPM,
        );
        updateWednesdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setWednesdayEndHour(selectedHour);

        const theStartVal = convertToHHMM(
          wednesdayStartHour,
          wednesdayStartMinute,
          wednesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          selectedHour,
          wednesdayEndMinute,
          wednesdayEndAMPM,
        );
        updateWednesdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "thu") {
      if (startOrEnd === "start") {
        setThursdayStartHour(selectedHour);

        const theStartVal = convertToHHMM(
          selectedHour,
          thursdayStartMinute,
          thursdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          thursdayEndHour,
          thursdayEndMinute,
          thursdayEndAMPM,
        );
        updateThursdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setThursdayEndHour(selectedHour);

        const theStartVal = convertToHHMM(
          thursdayStartHour,
          thursdayStartMinute,
          thursdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          selectedHour,
          thursdayEndMinute,
          thursdayEndAMPM,
        );
        updateThursdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "fri") {
      if (startOrEnd === "start") {
        setFridayStartHour(selectedHour);

        const theStartVal = convertToHHMM(
          selectedHour,
          fridayStartMinute,
          fridayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          fridayEndHour,
          fridayEndMinute,
          fridayEndAMPM,
        );
        updateFridayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setFridayEndHour(selectedHour);

        const theStartVal = convertToHHMM(
          fridayStartHour,
          fridayStartMinute,
          fridayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          selectedHour,
          fridayEndMinute,
          fridayEndAMPM,
        );
        updateFridayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "sat") {
      if (startOrEnd === "start") {
        setSaturdayStartHour(selectedHour);

        const theStartVal = convertToHHMM(
          selectedHour,
          saturdayStartMinute,
          saturdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          saturdayEndHour,
          saturdayEndMinute,
          saturdayEndAMPM,
        );
        updateSaturdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setSaturdayEndHour(selectedHour);

        const theStartVal = convertToHHMM(
          saturdayStartHour,
          saturdayStartMinute,
          saturdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          selectedHour,
          saturdayEndMinute,
          saturdayEndAMPM,
        );
        updateSaturdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "sun") {
      if (startOrEnd === "start") {
        setSundayStartHour(selectedHour);

        const theStartVal = convertToHHMM(
          selectedHour,
          sundayStartMinute,
          sundayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          sundayEndHour,
          sundayEndMinute,
          sundayEndAMPM,
        );
        updateSundayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setSundayEndHour(selectedHour);

        const theStartVal = convertToHHMM(
          sundayStartHour,
          sundayStartMinute,
          sundayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          selectedHour,
          sundayEndMinute,
          sundayEndAMPM,
        );
        updateSundayHours(theStartVal, theEndVal);
      }
    }
  };

  const handleMinuteChange = (dayOfWeek, startOrEnd, event) => {
    const selectedMinute = event.target.value;

    if (dayOfWeek === "mon") {
      if (startOrEnd === "start") {
        setMondayStartMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          mondayStartMinute,
          selectedMinute,
          mondayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          mondayEndHour,
          mondayEndMinute,
          mondayEndAMPM,
        );
        updateMondayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setMondayEndMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          mondayStartMinute,
          mondayEndMinute,
          mondayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          mondayEndHour,
          selectedMinute,
          mondayEndAMPM,
        );
        updateMondayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "tue") {
      if (startOrEnd === "start") {
        setTuesdayStartMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          tuesdayStartMinute,
          selectedMinute,
          tuesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          tuesdayEndHour,
          tuesdayEndMinute,
          tuesdayEndAMPM,
        );
        updateTuesdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setTuesdayEndMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          tuesdayStartMinute,
          tuesdayEndMinute,
          tuesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          tuesdayEndHour,
          selectedMinute,
          tuesdayEndAMPM,
        );
        updateTuesdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "wed") {
      if (startOrEnd === "start") {
        setWednesdayStartMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          wednesdayStartMinute,
          selectedMinute,
          wednesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          wednesdayEndHour,
          wednesdayEndMinute,
          wednesdayEndAMPM,
        );
        updateWednesdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setWednesdayEndMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          wednesdayStartMinute,
          wednesdayEndMinute,
          wednesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          wednesdayEndHour,
          selectedMinute,
          wednesdayEndAMPM,
        );
        updateWednesdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "thu") {
      if (startOrEnd === "start") {
        setThursdayStartMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          thursdayStartMinute,
          selectedMinute,
          thursdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          thursdayEndHour,
          thursdayEndMinute,
          thursdayEndAMPM,
        );
        updateThursdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setThursdayEndMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          thursdayStartMinute,
          thursdayEndMinute,
          thursdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          thursdayEndHour,
          selectedMinute,
          thursdayEndAMPM,
        );
        updateThursdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "fri") {
      if (startOrEnd === "start") {
        setFridayStartMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          fridayStartMinute,
          selectedMinute,
          fridayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          fridayEndHour,
          fridayEndMinute,
          fridayEndAMPM,
        );
        updateFridayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setFridayEndMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          fridayStartMinute,
          fridayEndMinute,
          fridayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          fridayEndHour,
          selectedMinute,
          fridayEndAMPM,
        );
        updateFridayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "sat") {
      if (startOrEnd === "start") {
        setSaturdayStartMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          saturdayStartMinute,
          selectedMinute,
          saturdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          saturdayEndHour,
          saturdayEndMinute,
          saturdayEndAMPM,
        );
        updateSaturdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setSaturdayEndMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          saturdayStartMinute,
          saturdayEndMinute,
          saturdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          saturdayEndHour,
          selectedMinute,
          saturdayEndAMPM,
        );
        updateSaturdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "sun") {
      if (startOrEnd === "start") {
        setSundayStartMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          sundayStartMinute,
          selectedMinute,
          sundayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          sundayEndHour,
          sundayEndMinute,
          sundayEndAMPM,
        );
        updateSundayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setSundayEndMinute(selectedMinute);

        const theStartVal = convertToHHMM(
          sundayStartMinute,
          sundayEndMinute,
          sundayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          sundayEndHour,
          selectedMinute,
          sundayEndAMPM,
        );
        updateSundayHours(theStartVal, theEndVal);
      }
    }
  };

  const handleAMPMChange = (dayOfWeek, startOrEnd, event) => {
    const selectedAMPM = event.target.value;

    if (dayOfWeek === "mon") {
      if (startOrEnd === "start") {
        setMondayStartAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          mondayStartMinute,
          mondayStartMinute,
          selectedAMPM,
        );
        const theEndVal = convertToHHMM(
          mondayEndHour,
          mondayEndMinute,
          mondayEndAMPM,
        );
        updateMondayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setMondayEndAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          mondayStartMinute,
          mondayStartMinute,
          mondayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          mondayEndHour,
          mondayEndMinute,
          selectedAMPM,
        );
        updateMondayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "tue") {
      if (startOrEnd === "start") {
        setTuesdayStartAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          tuesdayStartMinute,
          tuesdayStartMinute,
          selectedAMPM,
        );
        const theEndVal = convertToHHMM(
          tuesdayEndHour,
          tuesdayEndMinute,
          tuesdayEndAMPM,
        );
        updateTuesdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setTuesdayEndAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          tuesdayStartMinute,
          tuesdayStartMinute,
          tuesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          tuesdayEndHour,
          tuesdayEndMinute,
          selectedAMPM,
        );
        updateTuesdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "wed") {
      if (startOrEnd === "start") {
        setWednesdayStartAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          wednesdayStartMinute,
          wednesdayStartMinute,
          selectedAMPM,
        );
        const theEndVal = convertToHHMM(
          wednesdayEndHour,
          wednesdayEndMinute,
          wednesdayEndAMPM,
        );
        updateWednesdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setWednesdayEndAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          wednesdayStartMinute,
          wednesdayStartMinute,
          wednesdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          wednesdayEndHour,
          wednesdayEndMinute,
          selectedAMPM,
        );
        updateWednesdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "thu") {
      if (startOrEnd === "start") {
        setThursdayStartAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          thursdayStartMinute,
          thursdayStartMinute,
          selectedAMPM,
        );
        const theEndVal = convertToHHMM(
          thursdayEndHour,
          thursdayEndMinute,
          thursdayEndAMPM,
        );
        updateThursdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setThursdayEndAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          thursdayStartMinute,
          thursdayStartMinute,
          thursdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          thursdayEndHour,
          thursdayEndMinute,
          selectedAMPM,
        );
        updateThursdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "fri") {
      if (startOrEnd === "start") {
        setFridayStartAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          fridayStartMinute,
          fridayStartMinute,
          selectedAMPM,
        );
        const theEndVal = convertToHHMM(
          fridayEndHour,
          fridayEndMinute,
          fridayEndAMPM,
        );
        updateFridayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setFridayEndAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          fridayStartMinute,
          fridayStartMinute,
          fridayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          fridayEndHour,
          fridayEndMinute,
          selectedAMPM,
        );
        updateFridayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "sat") {
      if (startOrEnd === "start") {
        setSaturdayStartAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          saturdayStartMinute,
          saturdayStartMinute,
          selectedAMPM,
        );
        const theEndVal = convertToHHMM(
          saturdayEndHour,
          saturdayEndMinute,
          saturdayEndAMPM,
        );
        updateSaturdayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setSaturdayEndAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          saturdayStartMinute,
          saturdayStartMinute,
          saturdayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          saturdayEndHour,
          saturdayEndMinute,
          selectedAMPM,
        );
        updateSaturdayHours(theStartVal, theEndVal);
      }
    } else if (dayOfWeek === "sun") {
      if (startOrEnd === "start") {
        setSundayStartAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          sundayStartMinute,
          sundayStartMinute,
          selectedAMPM,
        );
        const theEndVal = convertToHHMM(
          sundayEndHour,
          sundayEndMinute,
          sundayEndAMPM,
        );
        updateSundayHours(theStartVal, theEndVal);
      } else if (startOrEnd === "end") {
        setSundayEndAMPM(selectedAMPM);

        const theStartVal = convertToHHMM(
          sundayStartMinute,
          sundayStartMinute,
          sundayStartAMPM,
        );
        const theEndVal = convertToHHMM(
          sundayEndHour,
          sundayEndMinute,
          selectedAMPM,
        );
        updateSundayHours(theStartVal, theEndVal);
      }
    }
  };

  return (
    <main className="SlotsManagementPage roboto">
      <div className="main-container">
        <div style={{ marginBottom: 30, width: "100%", display: "none" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "1rem",
            }}
          >
            <h3 style={{ fontSize: 14, fontWeight: 600 }}>Working Hours</h3>

            <button
              type="button"
              onClick={copyToAll}
              className="copy-time btn btn-dark"
            >
              Copy to all
            </button>
          </div>

          <FloatingContainer label={"Hours"}>
            <div className="restaurant-hours">
              <div className="hour-row">
                <label
                  style={{
                    color: "black",
                  }}
                  htmlFor="mondayHours"
                >
                  Monday
                </label>

                <div className="hour-picker">
                  <select
                    className="hoursSelect"
                    value={mondayStartHour}
                    onChange={(event) =>
                      handleHourChange("mon", "start", event)
                    }
                  >
                    {hours.map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={mondayStartMinute}
                    onChange={(event) =>
                      handleMinuteChange("mon", "start", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={mondayStartAMPM}
                    onChange={(event) =>
                      handleAMPMChange("mon", "start", event)
                    }
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>

                  <span style={{ marginLeft: 20, marginRight: 20 }}>to</span>

                  <select
                    className="hoursSelect"
                    value={mondayEndHour}
                    onChange={(event) => handleHourChange("mon", "end", event)}
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={mondayEndMinute}
                    onChange={(event) =>
                      handleMinuteChange("mon", "end", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={mondayEndAMPM}
                    onChange={(event) => handleAMPMChange("mon", "end", event)}
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>
                </div>
              </div>

              <div className="hour-row">
                <label
                  style={{
                    color: "black",
                  }}
                  htmlFor="mondayHours"
                >
                  Tuesday
                </label>

                <div className="hour-picker">
                  <select
                    className="hoursSelect"
                    value={tuesdayStartHour}
                    onChange={(event) =>
                      handleHourChange("tue", "start", event)
                    }
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={tuesdayStartMinute}
                    onChange={(event) =>
                      handleMinuteChange("tue", "start", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={tuesdayStartAMPM}
                    onChange={(event) =>
                      handleAMPMChange("tue", "start", event)
                    }
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>

                  <span style={{ marginLeft: 20, marginRight: 20 }}>to</span>

                  <select
                    className="hoursSelect"
                    value={tuesdayEndHour}
                    onChange={(event) => handleHourChange("tue", "end", event)}
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={tuesdayEndMinute}
                    onChange={(event) =>
                      handleMinuteChange("tue", "end", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={tuesdayEndAMPM}
                    onChange={(event) => handleAMPMChange("tue", "end", event)}
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>
                </div>
              </div>

              <div className="hour-row">
                <label
                  style={{
                    color: "black",
                  }}
                  htmlFor="mondayHours"
                >
                  Wednesday
                </label>

                <div className="hour-picker">
                  <select
                    className="hoursSelect"
                    value={wednesdayStartHour}
                    onChange={(event) =>
                      handleHourChange("wed", "start", event)
                    }
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={wednesdayStartMinute}
                    onChange={(event) =>
                      handleMinuteChange("wed", "start", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={wednesdayStartAMPM}
                    onChange={(event) =>
                      handleAMPMChange("wed", "start", event)
                    }
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>

                  <span style={{ marginLeft: 20, marginRight: 20 }}>to</span>

                  <select
                    className="hoursSelect"
                    value={wednesdayEndHour}
                    onChange={(event) => handleHourChange("wed", "end", event)}
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={wednesdayEndMinute}
                    onChange={(event) =>
                      handleMinuteChange("wed", "end", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={wednesdayEndAMPM}
                    onChange={(event) => handleAMPMChange("wed", "end", event)}
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>
                </div>
              </div>

              <div className="hour-row">
                <label
                  style={{
                    color: "black",
                  }}
                  htmlFor="mondayHours"
                >
                  Thursday
                </label>

                <div className="hour-picker">
                  <select
                    className="hoursSelect"
                    value={thursdayStartHour}
                    onChange={(event) =>
                      handleHourChange("thu", "start", event)
                    }
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={thursdayStartMinute}
                    onChange={(event) =>
                      handleMinuteChange("thu", "start", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={thursdayStartAMPM}
                    onChange={(event) =>
                      handleAMPMChange("thu", "start", event)
                    }
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>

                  <span style={{ marginLeft: 20, marginRight: 20 }}>to</span>

                  <select
                    className="hoursSelect"
                    value={thursdayEndHour}
                    onChange={(event) => handleHourChange("thu", "end", event)}
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={thursdayEndMinute}
                    onChange={(event) =>
                      handleMinuteChange("thu", "end", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={thursdayEndAMPM}
                    onChange={(event) => handleAMPMChange("thu", "end", event)}
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>
                </div>
              </div>

              <div className="hour-row">
                <label
                  style={{
                    color: "black",
                  }}
                  htmlFor="mondayHours"
                >
                  Friday
                </label>

                <div className="hour-picker">
                  <select
                    className="hoursSelect"
                    value={fridayStartHour}
                    onChange={(event) =>
                      handleHourChange("fri", "start", event)
                    }
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={fridayStartMinute}
                    onChange={(event) =>
                      handleMinuteChange("fri", "start", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={fridayStartAMPM}
                    onChange={(event) =>
                      handleAMPMChange("fri", "start", event)
                    }
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>

                  <span style={{ marginLeft: 20, marginRight: 20 }}>to</span>

                  <select
                    className="hoursSelect"
                    value={fridayEndHour}
                    onChange={(event) => handleHourChange("fri", "end", event)}
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={fridayEndMinute}
                    onChange={(event) =>
                      handleMinuteChange("fri", "end", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={fridayEndAMPM}
                    onChange={(event) => handleAMPMChange("fri", "end", event)}
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>
                </div>
              </div>

              <div className="hour-row">
                <label
                  style={{
                    color: "black",
                  }}
                  htmlFor="mondayHours"
                >
                  Saturday
                </label>

                <div className="hour-picker">
                  <select
                    className="hoursSelect"
                    value={saturdayStartHour}
                    onChange={(event) =>
                      handleHourChange("sat", "start", event)
                    }
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={saturdayStartMinute}
                    onChange={(event) =>
                      handleMinuteChange("sat", "start", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={saturdayStartAMPM}
                    onChange={(event) =>
                      handleAMPMChange("sat", "start", event)
                    }
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>

                  <span style={{ marginLeft: 20, marginRight: 20 }}>to</span>

                  <select
                    className="hoursSelect"
                    value={saturdayEndHour}
                    onChange={(event) => handleHourChange("sat", "end", event)}
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={saturdayEndMinute}
                    onChange={(event) =>
                      handleMinuteChange("sat", "end", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={saturdayEndAMPM}
                    onChange={(event) => handleAMPMChange("sat", "end", event)}
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>
                </div>
              </div>

              <div className="hour-row">
                <label
                  style={{
                    color: "black",
                  }}
                  htmlFor="mondayHours"
                >
                  Sunday
                </label>

                <div className="hour-picker">
                  <select
                    className="hoursSelect"
                    value={sundayStartHour}
                    onChange={(event) =>
                      handleHourChange("sun", "start", event)
                    }
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={sundayStartMinute}
                    onChange={(event) =>
                      handleMinuteChange("sun", "start", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={sundayStartAMPM}
                    onChange={(event) =>
                      handleAMPMChange("sun", "start", event)
                    }
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>

                  <span style={{ marginLeft: 20, marginRight: 20 }}>to</span>

                  <select
                    className="hoursSelect"
                    value={sundayEndHour}
                    onChange={(event) => handleHourChange("sun", "end", event)}
                  >
                    {hours.map((hour) => (
                      <option key={hour}>{hour}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={sundayEndMinute}
                    onChange={(event) =>
                      handleMinuteChange("sun", "end", event)
                    }
                  >
                    {minutes.map((minute) => (
                      <option key={minute}>{minute}</option>
                    ))}
                  </select>

                  <select
                    className="hoursSelect"
                    value={sundayEndAMPM}
                    onChange={(event) => handleAMPMChange("sun", "end", event)}
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>
                </div>
              </div>
            </div>
          </FloatingContainer>
        </div>

        <div className="filter-bar-top">
          <div className="filter-calendar-btn d-flex align-items-center fw-semibold">
            <button
            type="button"
              onClick={changeToPrevDate}
              style={{ borderWidth: 0, backgroundColor: "transparent" }}

            >
              <img src={leftArrow} style={{ width: 10 }} alt="<-"/>
            </button>

            <Datepicker
              value={date}
              onChange={setTheDate}
              clearIcon={null}
              calendarIcon={
                <>
                  <span
                    className="me-3"
                    style={{ textTransform: "uppercase", color: "#6E6D7A" }}
                  >
                    {formattedDate}
                  </span>

                  <FaRegCalendarAlt size={20} style={{ color: "#6E6D7A" }} />
                </>
              }
            />

            <button
            type="button"
              onClick={changeToNextDate}
              style={{ borderWidth: 0, backgroundColor: "transparent" }}
            >
              <img src={rightArrow} style={{ width: 10 }} alt="->" />
            </button>
          </div>
        </div>

        <section
          className="booking-slot-grid"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {filteredSlots.map((time) => {
            const matchingSlot = slots.find(
              (slot) => slot.slotStartTime === time,
            );
            return (
              <SlotItem
                key={time}
                id={time}
                time={time}
                style={{
                  color: "red",
                }}
                updateSlot={updateSlot}
                isAvailable={matchingSlot?.slotStatus !== "unavailable"}
              />
            );
          })}
        </section>
      </div>
    </main>
  );
};

export default SlotsManagement;
