//@ts-nocheck
import React from "react";
import { BookingCard } from "./bookingCard";

const PreviousBookings = ({ bookingData }) => {
  return (
    <div>
      {bookingData && bookingData.length !== 0 ? (
        bookingData.map((bookingData) => (
          <BookingCard bookingData={bookingData} />
        ))
      ) : (
        <div className=" text-black text-center">
          No Previous Bookings found
        </div>
      )}
    </div>
  );
};

export default PreviousBookings;
