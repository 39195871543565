// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // For extracting URL parameters
import { getIndividualBooking } from "../../API/tableReserve/tableReserveAPI"; // Your API call function

const IndividualBooking = () => {
  const { bookingId, userId, restaurantId } = useParams(); // Extract URL params
  const [bookingData, setBookingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch booking data using the API
  const callApi = async () => {
    try {
      const data = await getIndividualBooking(bookingId, userId, restaurantId);
      setBookingData(data); // Store the data
      setLoading(false); // Set loading to false
    } catch (err) {
      setError("Error fetching booking data");
      setLoading(false);
    }
  };

  useEffect(() => {
    callApi(); // Call the API when the component mounts
  }, [bookingId, userId, restaurantId]); // Dependency array to re-run on parameter change

  if (loading) return <div>Loading...</div>; // Show loading message
  if (error) return <div>{error}</div>; // Show error message

  // Display booking details if data is available
  return (
    <div
      style={{
        maxWidth: "900px",
        margin: "15px auto auto auto",
        padding: "30px",
        backgroundColor: "#2e2e2e", // Dark background
        borderRadius: "12px",
        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
        color: "#f1f1f1", // Light text color for dark theme
      }}
    >
      <h1 className="text-center" style={{ color: "#fff" }}>
        Booking Details
      </h1>

      <div className="row">
        <div className="col-md-6">
          <p>
            <strong>Booking ID:</strong> {bookingData.bookingId}
          </p>
          <p>
            <strong>User Name:</strong> {bookingData.user.name}
          </p>
          <p>
            <strong>Email:</strong> {bookingData.emailAddress}
          </p>
          <p>
            <strong>Phone Number:</strong> {bookingData.user.mobileNumber}
          </p>
        </div>
        <div className="col-md-6">
          <p>
            <strong>Party Size:</strong> {bookingData.partySize}
          </p>
          <p>
            <strong>Booking Status:</strong> {bookingData.status}
          </p>
        </div>
      </div>

      <h2 className="mt-4" style={{ color: "#fff" }}>
        Restaurant Details
      </h2>
      <div className="row">
        <div className="col-md-6">
          <p>
            <strong>Restaurant Name:</strong> {bookingData.restaurant.name}
          </p>
        </div>
        <div className="col-md-6">
          <p>
            <strong>Address:</strong> {bookingData.restaurant.formattedAddress}
          </p>
        </div>
      </div>

      <h3 className="mt-4" style={{ color: "#fff" }}>
        Slot Details
      </h3>
      <div className="row">
        <div className="col-md-6">
          <p>
            <strong>Slot Date:</strong> {bookingData.slot.slotDate}
          </p>
        </div>
        <div className="col-md-6">
          <p>
            <strong>Slot Time:</strong> {bookingData.slot.slotStartTime}
          </p>
        </div>
      </div>
    </div>
  );
};

export default IndividualBooking;
