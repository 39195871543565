//@ts-nocheck
import React, { useState } from "react";
import { RestaurantDetails } from "./restaurantDetails";
import { BookingDetails } from "./bookingDetails";
import { cancelBookingAPI } from "../../API/tableReserve/tableReserveAPI";
import { useNavigate } from "react-router-dom";
//import { toast } from "react-hot-toast";

export const BookingCard = ({ bookingData }: { bookingData: object }) => {
  const [showModal, setShowModal] = useState(false);
  const [cancel, setCancel] = useState(false);
  const navigate = useNavigate();

  const handleCancelConfirm = async () => {
    const response = await cancelBookingAPI(bookingData?.id, navigate);
    //toast.success("Your Booking has been CANCELLED !");
    setShowModal(false);
    window.location.reload();
    setCancel(true);
  };
  return (
    <div className="bookings-wrapper mb-4">
      <RestaurantDetails
        bookingData={bookingData}
        setShowModal={setShowModal}
        cancel={cancel}
      />

      <BookingDetails bookingData={bookingData} />

      {showModal && (
        <div
          className="modal fade show d-block"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1
                  className="modal-title fs-5"
                  id="exampleModalLabel"
                  style={{ color: "black" }}
                >
                  Status Confirmation
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body" style={{ color: "black" }}>
                Are you sure you want to CANCEL your booking?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleCancelConfirm}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
