import { useState, useEffect } from "react";

import ProfileThumbsSlider from "../../Components/ProfileThumbsSlider";
import Restaurant1 from "../../assets/images/profile/restaurant-1.jpg";
import Restaurant2 from "../../assets/images/profile/restaurant-2.jpg";
import Restaurant3 from "../../assets/images/profile/restaurant-3.jpg";
import Restaurant4 from "../../assets/images/profile/restaurant-4.jpg";
import {
  FloatingContainer,
  FloatingInput,
} from "../../Components/FloatingInput";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css";
import "react-clock/dist/Clock.css";
import { baseURL } from "../../constants/Api";
import axios from "axios";

import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AnalyticsUtil from "../../utils/GoogleAnalytics";
import CircularUnderLoad from "../../Components/CircularLoader";
import { FaSave,FaCheckCircle } from "react-icons/fa";

const Profile = () => {
  const [restaurantId, setRestaurantId] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [biggestTableSize, setBiggestTableSize] = useState(20);

  const [emailError, setEmailError] = useState("");
  const [contactPersonError, setContactPersonError] = useState("");
  const [contactNumberError, setContactNumberError] = useState("");
  const [biggestTableSizeError, setBiggestTableSizeError] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setRestaurantId(localStorage.getItem("restaurantId"));
    fetchProfile(localStorage.getItem("restaurantId")).finally(() =>
      setLoading(false),
    );
  }, []);

  const notify = () => {
    // First toast: Profile Updating
    toast.success("Profile Updating", {
      position: "top-right",
      autoClose: 3000, // Shorter duration for "Updating" toast
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Slide,
      icon: <FaSave color="green" />, // Save icon for updating
    });
  
    // Second toast: Changes Saved
    setTimeout(() => {
      toast.success("Changes have been saved", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Slide,
        icon: <FaCheckCircle color="green" />,
      });
    }, 3010);
  };
  const setTheEmailAddress = async (e) => {
    setEmailAddress(e.target.value);
  };

  const setTheContactNumber = async (e) => {
    setContactNumber(e.target.value);
  };

  const setTheContactPersonName = async (e) => {
    setContactPersonName(e.target.value);
  };

  const setTheBiggestTableSize = async (e) => {
    setBiggestTableSize(e.target.value);
  };

  const fetchProfile = async (restroId) => {
    if (restroId !== "") {
      try {
        const apiUrl = `${baseURL}/v1/restaurant/getRestaurantProfile/${restroId}/`;

        const response = await fetch(apiUrl);

        if (!response.ok) {
          throw new Error(`Failed to fetch slots. Status: ${response.status}`);
        }

        const data = await response.json();

        setName(data.restaurant.name);
        setAddress(data.restaurant.formattedAddress);
        setEmailAddress(data.restaurant.emailAddress);
        setContactPersonName(data.restaurant.contactPersonName);
        setContactNumber(data.restaurant.contactNumber|| '');
        setBiggestTableSize(data.restaurant.biggestTableSize);
      } catch (error) {
        console.error("Error fetching slots:", error.message);
      }
    }
  };

  function validateIndianMobileNumbers(input) {
    const cleanInput = input.replace(/\s/g, "");

    const singleNumberRegex = /^(\+91)?[6-9]\d{9}$/;

    if (singleNumberRegex.test(cleanInput)) {
      return true;
    }

    if (cleanInput.includes(",")) {
      const numbers = cleanInput.split(",");
      const allValid = numbers.every((number) =>
        singleNumberRegex.test(number),
      );

      return allValid;
    }

    return false;
  }

  function isValidName(str) {
    const hasDigit = /\d/.test(str);

    return !hasDigit;
  }

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  }

  const updateRestaurantProfile = async (val) => {
    let allGood = true;

    // if (emailAddress === "") {
    //   allGood = false;
    //   setEmailError("Email address is required");
    // } else {
    //   setEmailError("");
    //   allGood = true;
    // }

    if (emailAddress?.length > 0 && !isValidEmail(emailAddress)) {
      allGood = false;
      setEmailError("Email address must be a valid email");
    } else {
      setEmailError("");
      allGood = true;
    }

    if (contactPersonName === "") {
      allGood = false;
      setContactPersonError("Contact person name is required");
    } else {
      setContactPersonError("");
      allGood = true;
    }

    if (contactPersonName?.length > 0 && !isValidName(contactPersonName)) {
      allGood = false;
      setContactPersonError("Contact person name must not contain any digits");
    } else {
      setContactPersonError("");
      allGood = true;
    }

    if (contactNumber === "") {
      allGood = false;
      setContactNumberError("Contact number is required");
    } else {
      setContactNumberError("");
      allGood = true;
    }

    if (
      contactNumber?.length > 0 &&
      !validateIndianMobileNumbers(contactNumber)
    ) {
      allGood = false;
      setContactNumberError(
        "Contact number must be a 10-digit number or a comma-separated list of 10-digit numbers",
      );
    } else {
      setContactNumberError("");
      allGood = true;
    }

    if (biggestTableSize && biggestTableSize <= 0) {
      allGood = false;
      setBiggestTableSizeError("Biggest table size must be higher than zero");
    } else {
      setBiggestTableSizeError("");
      allGood = true;
    }

    console.log("allGood:", allGood);
    console.log("restaurantId:", restaurantId);

    if (allGood === true && restaurantId !== "") {
      try {
        const { data } = await axios.post(
          `${baseURL}/v1/restaurant/updateRestaurantProfile`,
          {
            restaurantId: restaurantId,
            emailAddress: emailAddress,
            contactPersonName: contactPersonName,
            contactNumber: contactNumber,
            biggestTableSize: biggestTableSize,
          },
        );

        // Handle the response data as needed
        //setResponse(data);
        notify();
      } catch (error) {
        // Handle error, e.g., display an error message
        console.error("Error making POST request:", error);
      }
    }
  };

  console.log("emailAddress:", emailAddress);
  console.log("contactPersonName:", contactPersonName);
  console.log("contactNumber:", contactNumber);
  console.log("biggestTableSize:", biggestTableSize);

  useEffect(() => {
    window.addEventListener("load", () => setLoading((s) => !s));
    return () =>
      window.removeEventListener("load", () => setLoading((s) => !s));
  }, []);

  useEffect(() => {
    const analytics = new AnalyticsUtil(
      import.meta.env.VITE_GOOGLE_TRACKING_ID,
    );
    analytics.sendPageView("/reservation/profile", "ReservationProfile Page");
  }, []);

  return loading ? (
    <main className="ProfilePage roboto">
      <div className="main-container">
        <div
          style={{
            marginTop: "40vh",
            textAlign: "center",
            marginInline: "auto",
            width: "100%",
          }}
        >
          <CircularUnderLoad />
          We are fetching details...
        </div>
      </div>
    </main>
  ) : (
    <main className="ProfilePage roboto">
      <div className="main-container">
        {/* <h1 className="h1 fw-semibold page-title">My Profile</h1> */}
        {/* <div className="custom-divider" /> */}

        <section className="profile-row" style={{ paddingTop: 0 }}>
          <div className="profile-photos" style={{ display: "none" }}>
            <ProfileThumbsSlider
              images={[Restaurant1, Restaurant2, Restaurant3, Restaurant4]}
            />

            <button
              type="button"
              className="custom-btn custom-btn-dark d-block mx-auto mt-4"
            >
              Save Changes
            </button>
          </div>

          <div className="edit-profile">
            <div className="edit-profile-form" style={{ boxShadow: "none" }}>
              <div>
                <FloatingInput
                  id="RestaurantName"
                  type={"text"}
                  name="Restaurant Name"
                  label={"Restaurant Name"}
                  placeholder={"Enter restaurant name"}
                  value={name}
                  disabled={true}
                />
                <FloatingInput
                  id="Address"
                  type={"text"}
                  name="Address"
                  label={"Address"}
                  value={address}
                  disabled={true}
                  placeholder={"Enter address"}
                />

                <div className="input-inline d-flex flex-wrap flex-md-nowrap gap-4">
                  <div className="w-100">
                    <FloatingInput
                      id="EmailAddress"
                      type={"email"}
                      name="Email address"
                      label={"Email address"}
                      placeholder={"Enter email address (optional)"}
                      value={emailAddress}
                      onChange={setTheEmailAddress}
                    />

                    {emailError.length > 0 && (
                      <span
                        style={{ color: "red", fontSize: 12, marginTop: -30 }}
                      >
                        {emailError}
                      </span>
                    )}
                    <FloatingInput
                      id="ContactNumber"
                      type={"text"}
                      name="Contact Number"
                      label={"Contact Number"}
                      placeholder={"Enter contact number"}
                      value={contactNumber}
                      onChange={setTheContactNumber}
                    />
                    {contactNumberError.length > 0 && (
                      <span style={{ color: "red", fontSize: 12 }}>
                        {contactNumberError}
                      </span>
                    )}
                    <FloatingInput
                      id="ContactPersonName"
                      type={"text"}
                      name="Contact Person Name"
                      label={"Contact Person Name"}
                      placeholder={"Enter contact person's name"}
                      value={contactPersonName}
                      onChange={setTheContactPersonName}
                    />

                    {contactPersonError.length > 0 && (
                      <span style={{ color: "red", fontSize: 12 }}>
                        {contactPersonError}
                      </span>
                    )}

                    <div style={{ height: 30 }} />

                    <FloatingInput
                      id="BiggestTableSize"
                      type={"number"}
                      name="Biggest Table Size"
                      label={"Biggest Table Size"}
                      placeholder={"Enter biggest table size"}
                      value={biggestTableSize}
                      onChange={setTheBiggestTableSize}
                      style={{
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                      }}
                    />
                    {biggestTableSizeError.length > 0 && (
                      <span style={{ color: "red", fontSize: 12 }}>
                        {biggestTableSizeError}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <button
                type="button"
                className="custom-btn custom-btn-dark d-block ms-auto mt-4"
                onClick={updateRestaurantProfile}
                onKeyUp={() => false}
              >
                Save Changes
              </button>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Profile;
