// @ts-nocheck
import React, { useEffect, useState } from "react";
import Avatar from "react-avatar-edit";
import ImageEdit from "../RestaurantMenu/Modals Layout/ImageEdit";
import classes from "../styleSheets/UpdateProfile.module.css";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { customizeMUIComponents } from "../styleSheets/muiCustomStyles";
import CloseIcon from "@mui/icons-material/Close";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { USER } from "../constants/localStorageKeys";
import { baseURL } from "../constants/Api";
import { DigitalOceanFetch } from "../API/digitalocean-fetch-images";
const userName = localStorage.getItem(USER.USERNAME);
const ProfilePic = ({
  selectedImage,
  setSelectedImage,
  imgEdit,
  setImgEdit,
  isFilepicked,
  setIsFilePicked,
  loading,
  setLoading,
  storeImage,
  setStoreImage,
}) => {
  const [open, setOpen] = useState(false);
  const [crop, setCrop] = useState(false);

  const handleOpen = () => {
    setImgEdit(true);
    setStoreImage(selectedImage);

    // setOpen(true)
  };
  const close = () => {
    // setOpen(false)
    console.log("storeimage", storeImage);
    setImgEdit(false);
    if (storeImage != null) {
      setSelectedImage(storeImage);
    } else {
      setSelectedImage("");
    }

    setStoreImage("");
  };

  const onCrop = (view) => {
    setSelectedImage(view);
    setCrop(view);
    setIsFilePicked(true);
  };
  const onClose = () => {
    // selectedImage(crop)
    setCrop(null);
  };

  // const saveImage = ()=>{
  //   setStoreImage([...storeImage, {crop}])
  //   setOpen(false)
  // }

  const handleClose = async () => {
    // console.log("sizzee",selectedImage)
    if (!selectedImage) {
      alert("Please upload an image first");
    } else if (selectedImage.size / 1024 > 1024) {
      alert("Image size should be not be greater than  1 MB");
    } else {
      //  const URLtoImage= dataURLtoFile(selectedImage)
      //  console.log(URLtoImage,'checking filee')

      const file = await converturlToFile(selectedImage);
      console.log(file, "file");
      const reader = new FileReader();
      reader.onload = function (e) {
        const checkImg = new Image();
        checkImg.src = e.target?.result;
        checkImg.onload = async () => {
          const width = checkImg.width;
          const height = checkImg.height;
          const aspectRatio = width / height;
          if (aspectRatio < 0.9 || aspectRatio > 1.1) {
            alert("aspect Ratio should be 1:1");
            setLoading(false);
          } else {
            try {
              const imgURL = await DigitalOceanFetch(file);
              setSelectedImage(imgURL);
              setLoading(false);
              setImgEdit(false);
            } catch (error) {
              console.log(error);
            }
          }
        };
      };
      reader.readAsDataURL(file);
      console.log("working..");
      setStoreImage("");
      setOpen(false);
    }
  };

  const handleImageClick = () => {
    setImgEdit(true);
    setOpen(true);
  };

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size / 1024 >= 4098) {
      alert("File is too big!");
      elem.target.value = "";
    }
  };
  const converturlToFile = async (img) => {
    let file;
    await fetch(img)
      .then((res) => res.blob())
      .then((blob) => {
        file = new File([blob], "File name", { type: "image/png" });
      });
    return file;
  };

  return (
    <>
      <Modal
        open={imgEdit}
        // open={open1}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={customizeMUIComponents.profilePic}>
          <div onClick={close} className={classes.close}>
            <CloseIcon />
          </div>
          {loading ? (
            <>Loading...</>
          ) : (
            <div>
              <div className={classes.profile}>
                <Avatar
                  width={190}
                  height={295}
                  onCrop={onCrop}
                  onClose={onClose}
                />

                <div style={{ display: "flex", justifyContent: "left" }}>
                  <Button
                    onClick={handleClose}
                    sx={{
                      width: "80px",
                      marginTop: "30px",
                      textTransform: "none",
                      backgroundColor: "#6A75CA",
                      color: "#E0E4FF",
                    }}
                    variant="contained"
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ProfilePic;
